// src/store/toastSlice.js
import { createSlice } from '@reduxjs/toolkit';
import {generateUUID} from "../utils/generateUUID";

// Initial state with an empty array for toasts
const initialState = {
    toasts: []
};

// Create the slice
const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        // Action to add a new toast
        addToast(state, action) {
            state.toasts.push({
                id: generateUUID(), // Unique ID for the toast
                message: action.payload.message,
                type: action.payload.type || 'info', // 'info', 'success', 'error'
            });
        },
        // Action to remove a toast by its ID
        removeToast(state, action) {
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
        },
        // Action to clear all toasts
        clearToasts(state) {
            state.toasts = [];
        }
    }
});

// Export the actions
export const { addToast, removeToast, clearToasts } = toastSlice.actions;

// Export the reducer to use in the store
export default toastSlice.reducer;
