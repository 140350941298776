// src/index.js
import React from 'react';
import {createRoot} from 'react-dom/client';
import App from './App';
import {Provider} from 'react-redux';
import store from './store/store';
import {BrowserRouter as Router} from 'react-router-dom';
import './scss/main.scss';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
    <Provider store={store}>
        <Router>
            <App/>
        </Router>
    </Provider>
);