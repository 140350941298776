import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const AttachmentModal = ({ showModal, handleClose, addAttachment }) => {
    const [filename, setFilename] = useState('');
    const [url, setUrl] = useState('');
    const [type, setType] = useState('');
    const [error, setError] = useState('');

    // A map to associate file extensions with their MIME types
    const extensionToMimeType = {
        pdf: 'application/pdf',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png',
        gif: 'image/gif',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        txt: 'text/plain',
        csv: 'text/csv',
        mp4: 'video/mp4',
        zip: 'application/zip',
        // Add more as needed
    };

    const handleUrlChange = (e) => {
        const url = e.target.value;
        const fileType = url.split('.').pop().toLowerCase();
        setUrl(url);

        // If no type has been manually set, try to derive it from the URL
        if (!type) {
            setType(extensionToMimeType[fileType] || fileType); // Use MIME type from the map, fallback to raw extension
        }
    };

    const handleAddAttachment = () => {
        if (!filename || !url) {
            setError('Both filename and URL are required.');
            return;
        }

        // Validate the URL
        try {
            new URL(url); // This will throw an error if the URL is invalid
        } catch {
            setError('Invalid URL format.');
            return;
        }

        // If type is not manually provided, attempt to auto-detect based on the URL
        let contentType = type;
        if (!contentType) {
            const fileType = url.split('.').pop().toLowerCase();
            contentType = extensionToMimeType[fileType] || fileType;
        }

        // Validate the contentType
        if (!contentType) {
            setError('Invalid file type or content type.');
            return;
        }

        // If everything is valid, add the attachment
        addAttachment({ filename, url, contentType });
        setFilename('');
        setUrl('');
        setType('');
        setError('');
        handleClose();
    };

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Anhang hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label>Dateiname</Form.Label>
                        <Form.Control
                            type="text"
                            value={filename}
                            onChange={(e) => setFilename(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Datei-URL</Form.Label>
                        <Form.Control
                            type="text"
                            value={url}
                            onChange={handleUrlChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Dateityp</Form.Label>
                        <Form.Control
                            type="text"
                            value={type}
                            onChange={(e) => setType(e.target.value)} // Allow manual input of contentType
                            placeholder="Optional: Leave blank to auto-detect"
                        />
                    </Form.Group>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Abbrechen
                </Button>
                <Button variant="primary" onClick={handleAddAttachment}>
                    Anhang hinzufügen
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AttachmentModal;
