// src/store/store.js
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import settingsReducer from './localSettingsSlice';
import toastReducer from './toastSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        settings: settingsReducer,
        toast: toastReducer,
    },
});

export default store;
