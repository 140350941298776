// src/api/axiosInstance.js
import axios from 'axios';

// Configure Axios to automatically include cookies in requests.
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true  // Ensure credentials (cookies) are sent with requests
});

// Optional: Add interceptors if needed to manage requests and responses globally.
axiosInstance.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
