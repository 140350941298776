// src/components/DataTable.js
import React from 'react';

const DataTable = ({data, columns, onReload, isLoading}) => {
    return (
        <div>
            <i onClick={onReload} className={"bi bi-arrow-clockwise btn"}></i>
            {isLoading && <div>Daten werden geladen...</div>}
            <table className="table">
                <thead>
                <tr className="table-info">
                    {columns.map((column, index) => (
                        <th key={index} scope="col">{column.header}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map(item => (
                    <tr key={item.id} className="table-primary">
                        {columns.map((column, index) => (
                            <td key={index}>{column.accessor(item)}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default DataTable;
