import {useDispatch, useSelector} from 'react-redux';
import {resetUser, setUser} from '../store/userSlice';
import {useNavigate} from 'react-router-dom';
import {useEffect} from 'react';
import axiosInstance from "../middleware/axiosInstance";
import {updateSettings, updateSettingsAPI} from "../store/localSettingsSlice";
import {addToast} from "../store/toastSlice";

let firstAuthenticate = false;

export const useAuth = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const settings = useSelector((state) => state.settings);

    useEffect(() => {
        const authenticate = async () => {
            if (settings.isLoggedIn) {
                try {
                    // Automatically send the cookies (token, sessionToken) with the request
                    const response = await axiosInstance.get(`/me`);

                    if (response.status === 200) {
                        // If successful, dispatch login success
                        const me = response.data;

                        if (!firstAuthenticate) {
                            dispatch(addToast({
                                id: Date.now(),
                                message: 'Welcome back, champion! <br /> You’re still logged in! 🌟',
                                type: 'info'
                            }));
                            firstAuthenticate = true;
                        }

                        // Dispatch login success to store user and tokens in global state
                        dispatch(setUser(me.user));
                    }
                } catch (error) {
                    console.error('Authentication failed:', error);
                }
            }
        };
        authenticate();
    }, [dispatch, navigate]);

    const logout = async () => {
        try {
            // Call API to invalidate session; cookies will be sent automatically
            await axiosInstance.post(`/auth/logout`);
        } catch (error) {
            console.error('Logout failed:', error.response ? error.response.data : error.message);
        }

        // Update Redux state and API with cookie consent
        dispatch(updateSettingsAPI({ isLoggedIn: false }));

        dispatch(addToast({ id: Date.now(), message: 'Successful logged out!', type: 'success' }));

        // Clear state by dispatching the logout action
        dispatch(resetUser());
    };

    const login = async (username, password) => {

        console.log(1)

        try {

            console.log(2)

            await axiosInstance.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
                username,
                password,
            });

            console.log(3)

            const userResponse = await axiosInstance.get(`${process.env.REACT_APP_API_URL}/me`,);

            console.log(4)

            dispatch(setUser(userResponse.data));
            dispatch(updateSettings({isLoggedIn: true}));
            navigate('/dashboard');
        } catch (error) {

            console.log(error)

            console.error('Login failed: ', error.response ? error.response.data : error.message);
        }
    };

    return {
        logout,
        login
    };
};
